<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Initial</label>

            <b-form-input
              id="input-large"
              v-model="filter.inisial"
              size="lg"
              placeholder="Initial"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Name</label>

            <b-form-input
              id="input-large"
              v-model="filter.name"
              size="lg"
              placeholder="Name"
            ></b-form-input>
          </div>
        </div>

        <div class="d-flex mt-5 justify-content-end">
          <button class="btn btn-primary btn-md" @click="getPics(true)">
            <i class="flaticon2-search-1"></i> Filter
          </button>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="d-flex my-3 justify-content-end">
        <a
          role="button"
          @click="loadPic()"
          tabindex="0"
          href="#"
          target="_self"
          class="btn btn-primary"
          >Load PIC</a
        >
        <button
          class="btn btn-success btn-md"
          @click="showAddModal"
          style="margin-left:5px;"
        >
          <i class="flaticon-user-add"></i> Add PIC
        </button>
      </div>
      <div class="card-body p-0">
        <div class="table-loader-pic">
          <div
            class="text-center"
            v-bind:class="{
              'd-none': loadingActive
            }"
          >
            <div class="text-center">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden"></span>
              </div>
            </div>
          </div>
        </div>
        <!-- :data="$store.getters.getterPic" -->
        <complete-table :data="pics" :loading="loading">
          <template #header>
            <tr class="text-left">
              <th style="min-width: 100px">NIK</th>

              <th style="min-width: 100px" class="pl-7">
                <span class="text-dark-75">Initial</span>
              </th>
              <th style="min-width: 100px">Nama</th>
              <th style="min-width: 100px">Department</th>
              <th style="min-width: 100px">Division</th>
              <th style="min-width: 100px">Active Status</th>
              <th style="min-width: 100px">Employee Status</th>
              <th style="min-width: 101px">Action</th>
            </tr>
          </template>

          <template #defaultBody="{ item: res, }">
            <tr
              class="border-0"
              v-bind:key="res.code"
              v-bind:class="{
                'd-none': tableLoading
              }"
            >
              <td class="pl-0 pt-8">
                <span
                  class=" ml-5 text-dark-75 font-weight-bolder d-block font-size-lg "
                >
                  {{ res.nik }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ res.code }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ res.name }}
                </span>
              </td>

              <td>
                <span
                  class=" text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ res.department }}
                </span>
              </td>

              <td>
                <span
                  class=" text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ res.division }}
                </span>
              </td>

              <td>
                <span
                  class=" text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  <!-- <div v-if="res.status_active==0">
                  <span class="ml-0 mr-2 black--text v-chip v-chip--label theme--light v-size--small red lighten-4"><span class="v-chip__content"> Tidak Aktif </span></span>
                </div>

                <div v-if="res.status_active==1">
                  <span class="ml-0 mr-2 black--text v-chip v-chip--label theme--light v-size--small green lighten-4"><span class="v-chip__content"> Aktif </span></span>
                </div> -->
                  <v-chip
                    v-if="res.status_active == 0"
                    class="ml-0 mr-2 black--text"
                    style="background-color:#fd397a!important"
                    label
                    small
                  >
                    Nonactive
                  </v-chip>

                  <v-chip
                    v-if="res.status_active == 1"
                    style="background-color:#b2dfdb!important"
                    class="ml-0 mr-2 black--text"
                    label
                    small
                  >
                    Active
                  </v-chip>
                </span>
              </td>

              <td>
                <span
                  class=" text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{
                    res.employee === "non_pegawai"
                      ? `non employee`
                      : res.employee
                  }}
                </span>
              </td>

              <td class="pr-0">
                <a
                  class="
                        btn btn-light-success
                        font-weight-bolder font-size-sm
                      "
                  @click.prevent="showEditModal(res)"
                >
                  <i class="flaticon-edit"></i>
                </a>
              </td>
            </tr>
          </template>
        </complete-table>
      </div>
    </div>

    <b-modal ref="add-pic" hide-footer title="Form Add PIC">
      <div class="d-block text-center">
        <form>
          <div class="row">
            <!-- INISIAL -->
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <div role="group">
                <label for="input-live">Initial *</label>
                <b-form-input
                  id="input-live"
                  v-model="input.codepic"
                  :state="inputCodePicState"
                  aria-describedby="input-live-help input-live-feedback"
                  placeholder="Initial PIC"
                  trim
                ></b-form-input>

                <b-form-invalid-feedback id="input-live-feedback">
                  minimum 3 char
                </b-form-invalid-feedback>
              </div>
            </div>

            <!-- NIK -->
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <div role="group">
                <label for="input-live">NIK</label>
                <b-form-input
                  id="input-live"
                  v-model="input.nik"
                  placeholder="NIK"
                ></b-form-input>
              </div>
            </div>

            <!-- NAMA -->
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <div role="group">
                <label for="input-live">Name *</label>
                <b-form-input
                  id="input-live"
                  v-model="input.namepic"
                  :state="inputNamePicState"
                  aria-describedby="input-live-help input-live-feedback"
                  placeholder="Name PIC"
                  trim
                ></b-form-input>

                <b-form-invalid-feedback id="input-live-feedback">
                  PIC name can't be empty
                </b-form-invalid-feedback>
              </div>
            </div>

            <!-- POSITION -->
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Position *</label>

              <b-form-select
                v-model="input.positionpic"
                :options="positionOptions"
                size="lg"
              ></b-form-select>
            </div>

            <!-- EMAIL -->
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <div role="group">
                <label for="input-live">Email</label>
                <b-form-input
                  id="input-live"
                  v-model="input.email"
                  :state="inputEmailPicState"
                  aria-describedby="input-live-help input-live-feedback"
                  placeholder="Email"
                  trim
                ></b-form-input>

                <b-form-invalid-feedback id="input-live-feedback">
                  email format wrong
                </b-form-invalid-feedback>
              </div>
            </div>

            <!-- PHONE -->
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <div role="group">
                <label for="input-live">Phone</label>
                <b-form-input
                  id="input-live"
                  v-model="input.phone"
                ></b-form-input
                >Design
              </div>
            </div>

            <!-- DIRECTORATE -->
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <div role="group">
                <label for="input-live">Directorate</label>
                <b-form-input
                  id="input-live"
                  v-model="input.directorate"
                ></b-form-input>
              </div>
            </div>

            <!-- DIVISION -->
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Division</label>

              <b-form-select
                v-model="input.division"
                :options="divisionOptions"
                size="lg"
              ></b-form-select>
            </div>

            <!-- DEPARTMENT -->
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Department</label>

              <b-form-select
                v-model="input.department"
                :options="departmentOptions"
                size="lg"
              ></b-form-select>
            </div>

            <!-- FUNCTIONAL -->
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <div role="group">
                <label for="input-live">Functional</label>
                <b-form-input
                  id="input-live"
                  v-model="input.functional"
                ></b-form-input>
              </div>
            </div>

            <!-- FUNCTIONAL TITLE -->
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <div role="group">
                <label for="input-live">Functional Title</label>
                <b-form-input
                  id="input-live"
                  v-model="input.functional_title"
                ></b-form-input>
              </div>
            </div>

            <!-- SEX -->
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Gender</label>

              <b-form-select
                v-model="input.sex"
                :options="options.sex"
                size="lg"
              ></b-form-select>
            </div>

            <!-- PERUSAHAAN -->
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <div role="group">
                <label for="input-live">Company</label>
                <b-form-input
                  id="input-live"
                  v-model="input.company"
                ></b-form-input>
              </div>
            </div>

            <!-- STATUS -->
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Status</label>

              <b-form-select
                v-model="input.status_active"
                :options="options.status"
                size="lg"
              ></b-form-select>
            </div>

            <!-- STATUS PEGAWAI-->
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Employee Status</label>

              <b-form-select
                v-model="input.employee"
                :options="options.employee"
                size="lg"
              ></b-form-select>
            </div>
          </div>
        </form>
      </div>

      <div class="d-flex mt-2">
        <b-button
          class="btn btn-success mr-2"
          block
          @click.prevent="savePic()"
          :disabled="disableButton()"
          >Save</b-button
        >

        <b-button class="btn btn-secondary mt-0" block @click="closeAddModal"
          >Cancel</b-button
        >
      </div>
    </b-modal>

    <b-modal ref="edit-pic" hide-footer title="Form Ubah PIC">
      <div class="d-block text-center">
        <form>
          <div class="row">
            <!-- INISIAL -->
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <div role="group">
                <label for="input-live">Initial *</label>
                <b-form-input
                  id="input-live"
                  v-model="update.new_code"
                ></b-form-input>
              </div>
            </div>

            <!-- NIK -->
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <div role="group">
                <label for="input-live">NIK</label>
                <b-form-input
                  id="input-live"
                  v-model="update.nik"
                  placeholder="Masukan Nik"
                ></b-form-input>
              </div>
            </div>

            <!-- NAMA -->
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <div role="group">
                <label for="input-live">Name *</label>
                <b-form-input
                  id="input-live"
                  v-model="update.namepic"
                  :state="updateNamePicState"
                  aria-describedby="input-live-help input-live-feedback"
                  trim
                ></b-form-input>

                <b-form-invalid-feedback id="input-live-feedback">
                  PIC Name Can't Be Empty
                </b-form-invalid-feedback>
              </div>
            </div>

            <!-- POSITION -->
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Position *</label>

              <b-form-select
                v-model="update.positionpic"
                :options="positionOptions"
                size="lg"
              ></b-form-select>
            </div>

            <!-- EMAIL -->
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <div role="group">
                <label for="input-live">Email</label>
                <b-form-input
                  id="input-live"
                  v-model="update.email"
                  :state="inputEmailPicState"
                  aria-describedby="input-live-help input-live-feedback"
                  trim
                ></b-form-input>

                <b-form-invalid-feedback id="input-live-feedback">
                  Email format wrong
                </b-form-invalid-feedback>
              </div>
            </div>

            <!-- PHONE -->
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <div role="group">
                <label for="input-live">Phone</label>
                <b-form-input
                  id="input-live"
                  v-model="update.phone"
                ></b-form-input>
              </div>
            </div>

            <!-- DIRECTORATE -->
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <div role="group">
                <label for="input-live">Directorate</label>
                <b-form-input
                  id="input-live"
                  v-model="update.directorate"
                ></b-form-input>
              </div>
            </div>

            <!-- DIVISION -->
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Division</label>

              <b-form-select
                v-model="update.division"
                :options="divisionUpdateOptions"
                size="lg"
              ></b-form-select>
            </div>

            <!-- DEPARTMENT -->
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Department</label>

              <b-form-select
                v-model="update.department"
                :options="departmentUpdateOptions"
                size="lg"
              ></b-form-select>
            </div>

            <!-- FUNCTIONAL -->
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <div role="group">
                <label for="input-live">Functional</label>
                <b-form-input
                  id="input-live"
                  v-model="update.functional"
                ></b-form-input>
              </div>
            </div>

            <!-- FUNCTIONAL TITLE -->
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <div role="group">
                <label for="input-live">Functional Title</label>
                <b-form-input
                  id="input-live"
                  v-model="update.functional_title"
                ></b-form-input>
              </div>
            </div>

            <!-- SEX -->
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Gender</label>

              <b-form-select
                v-model="update.sex"
                :options="options.sex"
                size="lg"
              ></b-form-select>
            </div>

            <!-- COMPANY -->
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <div role="group">
                <label for="input-live">Company</label>
                <b-form-input
                  id="input-live"
                  v-model="update.company"
                ></b-form-input>
              </div>
            </div>

            <!-- STATUS -->
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Status</label>

              <b-form-select
                v-model="update.status_active"
                :options="options.status"
                size="lg"
              ></b-form-select>
            </div>

            <!-- STATUS PEGAWAI-->
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Employee Status</label>

              <b-form-select
                v-model="update.employee"
                :options="options.employee"
                size="lg"
              ></b-form-select>
            </div>
          </div>
        </form>
      </div>

      <div class="d-flex mt-2">
        <b-button
          class="btn btn-success mr-2"
          block
          @click.prevent="saveEditPic()"
          :disabled="disableUpdateButton()"
        >
          <div class="text-center">
            <b-spinner
              variant="primary"
              label="Spinning"
              v-bind:class="{
                'd-none': loaderEdit
              }"
            ></b-spinner>
          </div>
          <div
            class="text-center"
            v-bind:class="{
              'd-none': loaderEditText
            }"
          >
            Save
          </div>
        </b-button>

        <b-button class="btn btn-secondary mt-0" block @click="closeEditModal"
          >Cancel</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { GET_POSITION } from "@/core/services/store/position.module";
import { GET_DIVISION } from "../../../core/services/store/division.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_LIST_PIC } from "@/core/services/store/pic.module.js";
import { SAVE_PIC } from "@/core/services/store/pic.module.js";
import { DELETE_PIC } from "@/core/services/store/pic.module.js";
import { EDIT_PIC } from "@/core/services/store/pic.module.js";
import { SEARCH_PIC } from "@/core/services/store/pic.module.js";
import { GET_PIC } from "@/core/services/store/pic.module.js";
import { LOAD_PIC } from "@/core/services/store/pic.module.js";
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import CompleteTable from "../../content/widgets/advance-table/CompleteTable";
import { arrayMoreThanOne } from "../../../core/helper/array-validation.helper";
import { GET_DEPARTMENT } from "../../../core/services/store/department.module";

Vue.use(VueAxios, axios);

export default {
  name: "pic",
  components: { CompleteTable },
  data() {
    return {
      nextText: "Next",
      prevText: "Prev",
      options: {
        sex: [
          { value: null, text: "Choose Gender" },
          { value: "Laki-laki", text: "Man" },
          { value: "Perempuan", text: "Woman" }
        ],
        status: [
          { value: null, text: "Choose Active Status" },
          { value: "1", text: "Active" },
          { value: "0", text: "Non Active" }
        ],
        employee: [
          { value: null, text: "Choose Employee Status" },
          { value: "pegawai", text: "Employee" },
          { value: "non_pegawai", text: "Non Employee" }
        ]
      },
      filter: {
        inisial: null,
        name: null
      },
      update: {
        new_code: "",
        namepic: "",
        codepic: "",
        positionpic: null,
        nik: "",
        email: "",
        phone: "",
        directorate: "",
        division: null,
        department: null,
        functional: "",
        functional_title: "",
        sex: null,
        status_active: null,
        employee: null,
        company: null
      },
      input: {
        namepic: "",
        codepic: "",
        positionpic: null,
        nik: "",
        email: "",
        phone: "",
        directorate: "",
        division: null,
        department: null,
        functional: "",
        functional_title: "",
        sex: null,
        status_active: null,
        employee: null,
        company: null
      },
      loadingPrev: false,
      loadingNext: false,
      loadingActive: false,
      tableLoading: false,
      loaderEdit: true,
      loaderEditText: false,
      tableMainPic: true
    };
  },
  methods: {
    loadPic() {
      this.$store.dispatch(LOAD_PIC).then(() => {
        this.$store.dispatch(GET_PIC, {
          params: this.filter
        });
      });
    },

    disableButton() {
      if (
        this.inputCodePicState &&
        this.inputNamePicState &&
        this.input.positionpic != null
      ) {
        return false;
      }
      return true;
    },

    disableUpdateButton() {
      if (this.updateNamePicState && this.update.positionpic != null) {
        return false;
      }
      return true;
    },

    getPics(filter = false) {
      if (!arrayMoreThanOne(this.pics) || filter) {
        this.$store.dispatch(GET_PIC, {
          params: this.filter
        });
      }
    },

    getPosition() {
      if (!(this.positions.length > 0)) {
        this.$store.dispatch(GET_POSITION, this.filter);
      }
    },

    getDivision() {
      if (!(this.positions.length > 0)) {
        this.$store.dispatch(GET_DIVISION, this.filter);
      }
    },

    getDepartmen() {
      if (!(this.departments.length > 0)) {
        this.$store.dispatch(GET_DEPARTMENT, this.filter);
      }
    },

    showAddModal() {
      this.$refs["add-pic"].show();
    },

    closeAddModal() {
      this.$refs["add-pic"].hide();

      this.initial_pic_add = "";
      this.name_pic_add = "";
    },

    showEditModal(pic) {
      var division = this.divisionUpdateOptions.find(x=> x.text == pic.division);
      var departement = this.departmentUpdateOptions.find(x=> x.text == pic.department);
      Object.assign(this.update, {
        namepic: pic.name,
        codepic: pic.code,
        new_code: pic.code,
        positionpic: pic.position
          ? pic.position.code + "." + pic.position.name
          : null,
        nik: pic.nik,
        email: pic.email,
        phone: pic.phone,
        directorate: pic.directorate,
        division: division != undefined ? division.value : "",
        department: departement != undefined ? departement.value : "",
        functional: pic.functional,
        functional_title: pic.functional_title,
        sex: pic.sex,
        status_active: pic.status_active,
        employee: pic.employee,
        company: pic.company
      });
      this.$refs["edit-pic"].show();
    },

    savePic() {
      var positionRaw = this.input.positionpic.split(".");
      var divisionRaw = this.input.division
        ? this.input.division.split(".")
        : null;
      var departmentRaw = this.input.department
        ? this.input.department.split(".")
        : null;

      var position = {
        code: positionRaw[0],
        name: positionRaw[1]
      };
      if (divisionRaw != null) {
        var division = {
          code: divisionRaw[0],
          name: divisionRaw[1]
        };
      } else {
        division = divisionRaw;
      }
      if (departmentRaw != null) {
        var department = {
          code: departmentRaw[0],
          name: departmentRaw[1]
        };
      } else {
        department = departmentRaw;
      }

      var post = {
        code: this.input.codepic,
        name: this.input.namepic,
        nik: this.input.nik,
        position: position,
        email: this.input.email,
        phone: this.input.phone,
        directorate: this.input.directorate,
        division: division,
        department: department,
        functional: this.input.functional,
        functional_title: this.input.functional_title,
        sex: this.input.sex,
        status_active: this.input.status_active,
        employee: this.input.employee,
        company: this.input.company
      };

      this.$store.dispatch(SAVE_PIC, post).then(() => {
        this.input= {
        namepic: "",
        codepic: "",
        positionpic: null,
        nik: "",
        email: "",
        phone: "",
        directorate: "",
        division: null,
        department: null,
        functional: "",
        functional_title: "",
        sex: null,
        status_active: null,
        employee: null,
        company: null
      };
        
        this.closeAddModal();
      });
    },

    async saveEditPic() {
      if (this.initial_pic_add && this.name_pic_add) {
        return true;
      }

      var positionRaw = this.update.positionpic.split(".");
      var position = {
        code: positionRaw[0],
        name: positionRaw[1]
      };
      var department = {
        code: this.update.department,
        name: ""
      };
      var division = {
        code: this.update.division,
        name: ""
      };
      var upd = {
        code: this.update.codepic,
        new_code:
          this.update.codepic === this.update.new_code
            ? null
            : this.update.new_code,
        name: this.update.namepic,
        nik: this.update.nik,
        position: position,
        email: this.update.email,
        phone: this.update.phone,
        directorate: this.update.directorate,
        division: division,
        department: department,
        functional: this.update.functional,
        functional_title: this.update.functional_title,
        sex: this.update.sex,
        status_active: this.update.status_active,
        employee: this.update.employee,
        company: this.update.company
      };

      this.loaderEdit = false;
      this.loaderEditText = true;
      this.loadingActive = false;
      this.tableMainPic = true;
      await this.$store.dispatch(EDIT_PIC, upd);
      this.loaderEdit = true;
      this.loaderEditText = false;
      this.closeEditModal();
      await this.$store.dispatch(GET_PIC, [{ start: 0, end: 10, page: 0 }]);
      this.loadingActive = true;
      this.tableMainPic = false;
    },

    deletePic(event) {
      var name = event.target.getAttribute("data-name");
      var code = event.target.getAttribute("data-code");

      var params = {
        name: name,
        code: code
      };

      this.$store.dispatch(DELETE_PIC, params);
    },

    closeEditModal() {
      this.$refs["edit-pic"].hide();
    },

    async nextPage() {
      var currPage = this.$store.getters.getterPagination;
      var activePage = currPage + 1;
      var perPage = 10;
      var startPage = perPage * activePage;
      var endPage = startPage + perPage;
      var page = [{ start: startPage, end: endPage, page: activePage }];
      this.loadingNext = true;
      this.tableLoading = true;
      this.loadingActive = false;
      await this.$store.dispatch(GET_LIST_PIC, page);
      this.loadingNext = false;
      this.tableLoading = false;
      this.loadingActive = true;
    },

    async prevPage() {
      var currPage = this.$store.getters.getterPagination;
      var activePage = currPage - 1;
      var end = this.$store.getters.getterEndPage - 10;
      var start = this.$store.getters.getterStartPage - 10;
      var page = [{ start: start, end: end, page: activePage }];
      this.loadingPrev = true;
      this.tableLoading = true;
      this.loadingActive = false;
      await this.$store.dispatch(GET_LIST_PIC, page);
      this.loadingPrev = false;
      this.tableLoading = false;
      this.loadingActive = true;
    },

    searchPic() {
      var code = this.search.searchCode;
      var param = { name: code };
      this.$store.dispatch(SEARCH_PIC, param);
    }
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "PIC" }]);

    await this.$store.dispatch(GET_LIST_PIC, [{ start: 0, end: 10, page: 0 }]);
    this.loadingActive = true;
    this.tableMainPic = false;

    this.getPosition();
    this.getDivision();
    this.getDepartmen();
    this.getPics();
  },
  computed: {
    ...mapState({
      loading: state => state.pic.loading,
      pics: state => state.pic.pics?.data,
      errors: state => state.position.errors,
      positions: state => state.position.positions?.data ?? [],
      showPics: state => state.listPic,
      divisions: state => state.division.divisions?.data ?? [],
      departments: state => state.department.departments?.data ?? []
    }),

    positionOptions() {
      let positionOptions = [];

      if (arrayMoreThanOne(this.positions)) {
        positionOptions = this.positions.map(position => {
          return {
            value: position?.code + "." + position?.name,
            text: position?.name
          };
        });
      }

      return [{ value: null, text: "Choose Position" }, ...positionOptions];
    },

    divisionOptions() {
      let divisionOption = [];

      if (arrayMoreThanOne(this.divisions)) {
        divisionOption = this.divisions.map(division => {
          return {
            value: division?.code + "." + division?.name,
            text: division?.name
          };
        });
      }

      return [{ value: null, text: "Choose Divisi" }, ...divisionOption];
    },

    departmentOptions() {
      let departmentOption = [];

      if (arrayMoreThanOne(this.departments)) {
        departmentOption = this.departments.map(department => {
          return {
            value: department?.code + "." + department?.name,
            text: department?.name
          };
        });
      }

      return [{ value: null, text: "Choose Departement" }, ...departmentOption];
    },

    divisionUpdateOptions() {
      let divisionUpdateOption = [];

      if (arrayMoreThanOne(this.divisions)) {
        divisionUpdateOption = this.divisions.map(division => {
          return {
            value: division?.code,
            text: division?.name
          };
        });
      }

      return [{ value: null, text: "Choose Divisi" }, ...divisionUpdateOption];
    },

    departmentUpdateOptions() {
      let departmentUpdateOption = [];

      if (arrayMoreThanOne(this.departments)) {
        departmentUpdateOption = this.departments.map(department => {
          return {
            value: department?.code,
            text: department?.name
          };
        });
      }

      return [
        { value: null, text: "Choose Departement" },
        ...departmentUpdateOption
      ];
    },

    inputCodePicState() {
      return this.input.codepic.length > 2 ? true : false;
    },

    inputNamePicState() {
      return this.input.namepic.length > 0 ? true : false;
    },

    updateNamePicState() {
      return this.update.namepic.length > 0 ? true : false;
    },

    inputEmailPicState() {
      //eslint-disable-next-line
       return (this.input.email == "")? true : (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(this.input.email)) ? true : false;
    }
  }
};
</script>

<style></style>
